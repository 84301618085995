import { takeLatest, call, put, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import { isEmpty } from 'lodash';
import { COMPARE_PAGE, masterCardDataFetcher } from 'appConstants';
import { AppState } from 'store/rootReducer';
import { getMasterCardDataParams, getSelectedLocationsInfo, isFeatureOrComponentEnabled } from 'store/settings';
import { locationsActions } from 'store/locations';
import { getActivePage, getPathname } from 'store/navigation';
import { getAddressInfo, getCountryNameByPostalCode, getCoordinates } from 'services/googleMaps';
import { ApiGateway } from 'services/apiGateway';
import { roundTo2Decimals } from 'services/utils';
import { localizationService, dataProviders } from 'services/localization';
import { defineLatestAvailablePeriod, filterResponseByLastAvailablePeriod } from 'store/masterCard/modifiers';
import { errorsSelectors } from 'store/errors/selectors';
import { masterCardActions } from './actions';
import { FetchMasterCardDataPayload, FetchMasterCardDataResponse, PostalCodesInfo } from './types';
import {
  getCompetitorsByPostalCode,
  getCountryCode,
  getDataForLastPeriod,
  getNewCustomersByPostalCode,
  getOverallSalesByPostalCode,
  getSelectedPeriod
} from './selectors';
import { getDetailedZipInfo, getPolygonCoordinates } from './postalCodesFetching';
import { showApiError } from '../errors/utils';

export function* fetchMasterCardData() {
  try {
    const state: AppState = yield select();
    const selectedLocations = getSelectedLocationsInfo(state);
    const period = getSelectedPeriod(state);
    const isComparePage = getPathname(state).includes(COMPARE_PAGE);
    const isErrorPage = errorsSelectors.getApiError(state).isDisplayed;
    const { partnerId, isCustomDemoData, cesPlansDemoEnv } = getMasterCardDataParams(state);
    if (!isComparePage) {
      if (selectedLocations.length === 1) {
        const [selectedLocation] = selectedLocations;
        const { features } = state.masterCard;
        const payload: FetchMasterCardDataPayload = {
          merchantSequenceKey: selectedLocation.merchant_sequence_key,
          mid: selectedLocation.merchant_number,
          features,
          partnerId,
          isCustomDemoData,
          cesPlansDemoEnv
        };

        const response: FetchMasterCardDataResponse = yield call(ApiGateway.fetchMasterCardData, payload, isErrorPage);
        const latestAvailablePeriod = defineLatestAvailablePeriod(
          response.result && !isEmpty(response.result) ? Object.keys(response.result) : null,
          period
        );

        if (latestAvailablePeriod !== period) {
          yield put(masterCardActions.setPeriod(latestAvailablePeriod));
        }

        localizationService.setCurrentCurrencies(dataProviders.mastercard, response.currencies);
        yield put(masterCardActions.fetchMasterCardData.success(response));
      } else {
        yield put(masterCardActions.resetMasterCardData());
      }
    }
  } catch (e) {
    console.error(e);
    yield put(masterCardActions.fetchMasterCardData.failure(e));
  }
}

export function* fetchMasterCardCompareData() {
  try {
    const state: AppState = yield select();
    const selectedLocations = getSelectedLocationsInfo(state);
    const mids = selectedLocations.map(l => l.merchant_number);
    const merchantSequenceKeys = selectedLocations.map(l => l.merchant_sequence_key);

    const period = getSelectedPeriod(state);
    const { isCustomDemoData, partnerId, cesPlansDemoEnv } = getMasterCardDataParams(state);

    if (selectedLocations.length > 0) {
      const response = yield call(ApiGateway.fetchMasterCardCompareData, {
        mids,
        partnerId,
        isCustomDemoData,
        cesPlansDemoEnv,
        merchantSequenceKeys
      });
      const filteredResponse = filterResponseByLastAvailablePeriod(response);
      const latestAvailablePeriod = defineLatestAvailablePeriod(response && Object.keys(filteredResponse), period);

      if (latestAvailablePeriod !== period) {
        yield put(masterCardActions.setPeriod(latestAvailablePeriod));
      }

      yield put(masterCardActions.fetchMasterCardCompareData.success(filteredResponse));
    }
  } catch (e) {
    console.error(e);
    yield put(masterCardActions.fetchMasterCardCompareData.failure(e));
  }
}

function* fetchGeoInfoForSelectedLocation() {
  try {
    yield put(masterCardActions.fetchGeoInfoForSelectedLocation.request());
    const state: AppState = yield select();
    const [selectedLocation] = getSelectedLocationsInfo(state);

    const zipInfo = yield getAddressInfo(`${selectedLocation.city} ${selectedLocation.zip}`);
    const country = getCountryNameByPostalCode(zipInfo, selectedLocation.zip);
    const coordinates = getCoordinates(zipInfo);

    yield put(masterCardActions.fetchGeoInfoForSelectedLocation.success({ country, locationCoordinates: coordinates }));
  } catch (e) {
    console.error(e);
    yield put(masterCardActions.fetchGeoInfoForSelectedLocation.failure(e));
  }
}

function* fetchPostalCodesInfo() {
  try {
    yield put(masterCardActions.fetchPostalCodesInfo.request());
    const state: AppState = yield select();
    const masterCardData = getDataForLastPeriod(state);
    const countryCode = getCountryCode(state);
    const { isPostalCodeFormattingNeeded } = getMasterCardDataParams(state);

    const postalCodesData = {
      transactions: getOverallSalesByPostalCode(masterCardData),
      newCustomers: getNewCustomersByPostalCode(masterCardData),
      competitors: getCompetitorsByPostalCode(masterCardData)
    };

    const newPostalCodesData: PostalCodesInfo = {
      transactions: [],
      newCustomers: [],
      competitors: []
    };

    // eslint-disable-next-line no-restricted-syntax
    for (const [type, postalCodes] of Object.entries(postalCodesData)) {
      const typeAccum = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [zip, percent] of Object.entries(postalCodes)) {
        try {
          const { address, coordinates } = yield getDetailedZipInfo(
            !isPostalCodeFormattingNeeded ? zip : zip.replace(/ /g, ''),
            countryCode
          );
          const { polygons } = yield getPolygonCoordinates(zip, countryCode);
          typeAccum.push({
            zip,
            percent: roundTo2Decimals(percent as number),
            address,
            coordinates,
            polygons
          });
        } catch (e) {
          console.error(e);
        }
      }
      newPostalCodesData[type] = typeAccum;
    }

    yield put(masterCardActions.fetchPostalCodesInfo.success({ postalCodesInfo: newPostalCodesData }));
  } catch (e) {
    console.error(e);
    yield put(masterCardActions.fetchPostalCodesInfo.failure(e));
  }
}

export function* masterCardSagas() {
  const store = yield select();
  yield takeLatest(
    [masterCardActions.fetchMasterCardData.request, getType(locationsActions.select)],
    fetchMasterCardData
  );

  if (
    isFeatureOrComponentEnabled(
      store,
      getActivePage(store)?.pageName ?? '',
      masterCardDataFetcher,
      'fetchGeoInfoForSelectedLocation'
    )
  ) {
    yield takeLatest(
      [masterCardActions.fetchMasterCardData.success, masterCardActions.setPeriod],
      fetchGeoInfoForSelectedLocation
    );
  }

  yield takeLatest(
    [masterCardActions.fetchGeoInfoForSelectedLocation.success, masterCardActions.setPeriod],
    fetchPostalCodesInfo
  );

  yield takeLatest(
    [
      masterCardActions.fetchMasterCardData.failure,
      masterCardActions.fetchGeoInfoForSelectedLocation.failure,
      masterCardActions.fetchPostalCodesInfo.failure
    ],
    showApiError
  );
}

export function* watchFetchMasterCardCompareData() {
  yield takeLatest(
    [masterCardActions.fetchMasterCardCompareData.request, getType(locationsActions.select)],
    fetchMasterCardCompareData
  );

  yield takeLatest([masterCardActions.fetchMasterCardCompareData.failure], showApiError);
}
