import { ActionType, createReducer } from 'typesafe-actions';
import { loyaltyMembersActions } from './actions';
import { LoyaltyMembersState } from './types';

export const loyaltyMembersInitialState = {
  loading: false,
  data: [],
  error: null,
  initial: true
};

export const loyaltyMembersReducer = createReducer<LoyaltyMembersState, ActionType<typeof loyaltyMembersActions>>(
  loyaltyMembersInitialState
)
  .handleAction(loyaltyMembersActions.fetchLoyaltyMembers.request, state => ({
    ...state,
    loading: true,
    error: null
  }))
  .handleAction(loyaltyMembersActions.fetchLoyaltyMembers.success, (state, { payload }) => ({
    ...state,
    loading: false,
    data: payload,
    error: null,
    initial: false
  }))
  .handleAction(loyaltyMembersActions.fetchLoyaltyMembers.failure, (state, { payload }) => ({
    ...state,
    loading: false,
    error: payload,
    initial: false
  }))
  .handleAction(loyaltyMembersActions.resetLoyaltyMembersStore, () => ({
    ...loyaltyMembersInitialState
  }));
