import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getLoyaltyPointsSetup } from 'store/settings';
import { Button } from '../../../../shared-components/Button';
import { CalculatorForm } from './CalculatorForm';
import { CalculatorSuggest } from './CalculatorSuggest';
import {
  CalculatorContainerTranslations,
  CalculatorFormTranslations,
  CalculatorSuggestTranslations,
  ProgramConstants
} from '../types';
import { deviceMedia } from '../../../../appConstants';

interface CalculatorContainerProps {
  className?: string;
  translations: CalculatorContainerTranslations & CalculatorFormTranslations & CalculatorSuggestTranslations;
  onApply: Function;
  toggleCalculator: Function;
}

export const MarginForCalculatorSideBar = 75;

const CalculatorFooter = styled.div`
  text-align: right;

  button:first-child {
    margin-right: 15px;
  }

  @media ${deviceMedia.mobile} {
    display: flex;

    Button {
      flex: 1 1 100%;
    }
  }
`;

const CalculatorWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CalculatorContainer = styled((props: CalculatorContainerProps) => {
  const { translations, className } = props;
  const loyaltyPointsSetup = useSelector(getLoyaltyPointsSetup);

  const suggestedProgramConstants = useMemo(() => {
    const rewardSetup =
      loyaltyPointsSetup && loyaltyPointsSetup.length > 0
        ? loyaltyPointsSetup.reduce<ProgramConstants['reward']>(
            (acc, { threshold, values: { under25, under50, above50 } }) => {
              acc[threshold] = { 20: under25, 35: under50, 75: above50 };
              return acc;
            },
            {}
          )
        : {
            50: {
              20: 5,
              35: 5,
              75: 5
            },
            75: {
              20: 10,
              35: 5,
              75: 5
            },
            100: {
              20: 10,
              35: 10,
              75: 5
            },
            150: {
              20: 15,
              35: 15,
              75: 5
            },
            200: {
              20: 20,
              35: 15,
              75: 10
            },
            250: {
              20: 25,
              35: 20,
              75: 15
            },
            300: {
              20: 30,
              35: 25,
              75: 15
            }
          };

    return {
      pointThreshold: {
        roundValue: 10,
        coefficient: 1.75
      },
      reward: rewardSetup,
      cost: {
        20: translations.COGOption1,
        35: translations.COGOption2,
        75: translations.COGOption3
      }
    };
  }, [translations.COGOption1, translations.COGOption2, translations.COGOption3, loyaltyPointsSetup]);

  const [state, setState] = useState<any>({ suggestData: {} });

  const resetState = () => setState({ suggestData: {} });

  const toggle = () => {
    props.toggleCalculator();
    resetState();
  };

  const onApply = () => {
    const { pointThreshold, reward } = state.suggestData;
    props.onApply({ pointThreshhold: pointThreshold, rewardAmount: reward });
    toggle();
  };

  const isDataAvailable = () => Object.keys(state.suggestData).length > 0;

  const countPointThreshold = (averageTicket: number, averageFrequency: number) => {
    const { pointThreshold: pointThresholdConstants, reward } = suggestedProgramConstants;
    const pointThreshold = Math.floor(averageTicket * averageFrequency * pointThresholdConstants.coefficient);

    const breakpoints = Object.keys(reward).map(breakpoint => parseInt(breakpoint, 10));

    return breakpoints.reduce((prev, curr) => {
      const roundedPrev = prev + pointThresholdConstants.roundValue;
      return pointThreshold <= roundedPrev ? prev : curr;
    });
  };

  const countSuggestData = (formData: any) => {
    const { averageTicket, averageFrequency, costOfGoods } = formData;

    const pointThreshold = countPointThreshold(averageTicket, averageFrequency);
    const reward = get(suggestedProgramConstants, `reward.${pointThreshold}.${costOfGoods}`, 0);
    const cost = (reward * costOfGoods) / 100;

    setState({
      suggestData: {
        pointThreshold,
        reward,
        cost
      }
    });
  };

  const costOfGoodsOptions = Object.entries(suggestedProgramConstants.cost).map(([value, label]) => ({
    value,
    label
  }));

  return (
    <CalculatorWrapper className={className}>
      <div>
        <CalculatorForm
          translations={translations}
          onFilled={countSuggestData}
          costOfGoodsOptions={costOfGoodsOptions}
          resetSuggestion={resetState}
        />
        <CalculatorSuggest data={state.suggestData} isDataAvailable={isDataAvailable()} translations={translations} />
      </div>
      <CalculatorFooter>
        <Button secondary onClick={toggle} data-test-id="loyalty-calculator-form-cancel-button">
          <strong>{translations.calculatorCancel}</strong>
        </Button>
        <Button
          primary
          onClick={onApply}
          disabled={!isDataAvailable()}
          data-test-id="loyalty-calculator-form-save-button"
        >
          <strong>{translations.calculatorApply}</strong>
        </Button>
      </CalculatorFooter>
    </CalculatorWrapper>
  );
})`
  padding: 25px;
  max-width: 700px;
`;
