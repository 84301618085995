import { takeLatest, put, call, select } from 'redux-saga/effects';
import { ApiGateway } from 'services/apiGateway';
import { emailTemplateEditorActions, templateEditorSelector } from 'store/emailMarketing/emailTemplateEditor';
import { getEmSettings, isDanubeCompany } from 'store/emailMarketing/settings';
import { isDanubeCompanyWithLoyaltyEnabled, isRewardsEnabled } from 'store/rewards/selectors';
import { getAccountName, getChockStoneDomain, getKatanaKey, getPublicSitesDomain } from 'store/settings/selectors';
import { getTemplateDataForSave } from 'store/emailMarketing/utils';
import { notificationsActions } from 'store/notifications';
import i18n from 'i18n';
import { formatString } from 'services/utils';
import { getMenuPages } from 'store/navigation';
import { getPagePath } from 'services/navigation';
import { history } from 'store/rootStore';
import { AppState } from 'store/rootReducer';
import { showApiError } from 'store/errors/utils';
import {
  EMAIL_MARKETING_AUTOMATIONS_SUB_PAGE,
  EMAIL_MARKETING_CRM_AUTOMATIONS_SUB_PAGE,
  EMAIL_MARKETING_ROOT_PAGE
} from 'store/constants';
import { getStatuses, getTemplates } from './selectors';
import { emailMarketingCrmAutomation } from './actions';
import { EventName } from './types';
import { convertServerTemplates } from '../campaignEditor';

const findEventName = (state: AppState, eventName: EventName): string => {
  const status = getStatuses(state).find(item => item.eventName === eventName);
  return status ? status.name : '';
};

export function* fetchStatuses() {
  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  try {
    const data = yield call(ApiGateway.crmAutomationStatus);
    yield put(emailMarketingCrmAutomation.fetchStatuses.success(data));
  } catch (e) {
    console.log(e);
    yield put(emailMarketingCrmAutomation.fetchStatuses.failure());
  }
}

export function* fetchTemplates() {
  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  try {
    yield fetchStatuses();
    const statuses = getStatuses(yield select());
    const statusesMap: { [key: string]: number } = statuses.reduce((map: { [key: string]: number }, template) => {
      map[template.name] = template.status; // eslint-disable-line no-param-reassign
      return map;
    }, {});

    const templates = yield call(ApiGateway.crmAutomationFetchTemplates);

    const mappedTemplates = convertServerTemplates(templates).map(template => ({
      ...template,
      img: `data:image/png;base64, ${template.img}`,
      campaignStatus: statusesMap[template.name]
    }));

    yield put(emailMarketingCrmAutomation.fetchTemplates.success(mappedTemplates));
  } catch (e) {
    console.error(e);
    yield put(emailMarketingCrmAutomation.fetchTemplates.failure());
  }
}

export function* saveTemplate(action: any, requestTemplates = true) {
  console.log('1 saveTemplate start !!!!!!!!!!!!!!!');

  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  try {
    const state = yield select();
    const editor = templateEditorSelector(state);
    const templates = getTemplates(state);
    const settings = getEmSettings(state);
    const isDanubeWithLoyalty = isDanubeCompanyWithLoyaltyEnabled(state);
    const isDanubeAccount = isDanubeCompany(state);
    const accountName = getAccountName(state);
    const katanaKey = getKatanaKey(state);
    const publicSitesDomain = getPublicSitesDomain(state);
    const chockStoneDomain = getChockStoneDomain(state);

    const { id = '' } = editor;
    const selectedTemplate = templates.find((template: any) => template.id === id);

    console.log('2 selectedTemplate - ', selectedTemplate);

    if (!selectedTemplate) {
      console.error('Error while saving automation template, selectedTemplate is not found');
      return;
    }

    const { name, AutomatedCampaignTypeName } = selectedTemplate;
    const data = yield getTemplateDataForSave(
      editor,
      settings,
      name,
      emailTemplateEditorActions.updateBox,
      isDanubeWithLoyalty,
      isDanubeAccount,
      accountName,
      katanaKey,
      publicSitesDomain,
      chockStoneDomain
    );

    console.log('3 data - ', data);
    console.log('3 AutomatedCampaignTypeName - ', AutomatedCampaignTypeName);

    // @ts-ignore
    const result = yield call(ApiGateway.crmAutomationSaveTemplate, { AutomatedCampaignTypeName, data });

    console.log('4 result - ', result);

    if (requestTemplates) yield fetchTemplates();

    console.log('5 fetchTemplates');

    yield put(emailMarketingCrmAutomation.saveTemplate.success());
  } catch (e) {
    console.error(e);
    yield put(emailMarketingCrmAutomation.saveTemplate.failure());
  }
}

export function* turnOn({ payload }: ReturnType<typeof emailMarketingCrmAutomation.turnOn.request>) {
  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  try {
    const { eventName, saveTemplateChanges, campaignStatus } = payload;
    const state = yield select();
    const pages = getMenuPages(state);

    const subpageToRedirect = isRewardsEnabled(state)
      ? EMAIL_MARKETING_AUTOMATIONS_SUB_PAGE
      : EMAIL_MARKETING_CRM_AUTOMATIONS_SUB_PAGE;
    const redirectionPath = getPagePath(pages, EMAIL_MARKETING_ROOT_PAGE, subpageToRedirect);

    if (!campaignStatus) {
      yield call(ApiGateway.crmAutomationTurnOn, eventName);
    }

    if (saveTemplateChanges) {
      yield saveTemplate({}, false);
      yield call(ApiGateway.crmAutomationUpdateCampaignTemplate, eventName);
    }

    yield fetchTemplates();
    yield put(emailMarketingCrmAutomation.turnOn.success());
    if (history.location.pathname !== redirectionPath) {
      history.push(redirectionPath);
    }
    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18n.t('crmAutomationSuccess', 'Success'),
        text: `${formatString(
          i18n.t('crmAutomationTurnOnSuccessMessage', '{0} automation has been turned on.'),
          findEventName(state, eventName)
        ).join(' ')}`
      })
    );
  } catch (e) {
    console.log(e);
    yield put(emailMarketingCrmAutomation.turnOn.failure());
  }
}

export function* turnOff({ payload }: ReturnType<typeof emailMarketingCrmAutomation.turnOff.request>) {
  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  try {
    const state = yield select();
    yield call(ApiGateway.crmAutomationTurnOff, payload);
    yield fetchTemplates();
    yield put(emailMarketingCrmAutomation.turnOff.success());
    yield put(
      notificationsActions.addNotification({
        type: 'toast',
        title: i18n.t('crmAutomationSuccess', 'Success'),
        text: `${formatString(
          i18n.t('crmAutomationTurnOffSuccessMessage', '{0} automation has been turned off.'),
          findEventName(state, payload)
        ).join(' ')}`
      })
    );
  } catch (e) {
    console.log(e);
    yield put(emailMarketingCrmAutomation.turnOff.failure());
  }
}

export function* emailMarketingCrmAutomationSagas() {
  // eslint-disable-line @typescript-eslint/explicit-function-return-type
  yield takeLatest(emailMarketingCrmAutomation.fetchStatuses.request, fetchStatuses);
  yield takeLatest(emailMarketingCrmAutomation.turnOn.request, turnOn);
  yield takeLatest(emailMarketingCrmAutomation.turnOff.request, turnOff);
  yield takeLatest(emailMarketingCrmAutomation.fetchTemplates.request, fetchTemplates);
  yield takeLatest(emailMarketingCrmAutomation.saveTemplate.request, saveTemplate);
  yield takeLatest(
    [
      emailMarketingCrmAutomation.fetchStatuses.failure,
      emailMarketingCrmAutomation.fetchTemplates.failure,
      emailMarketingCrmAutomation.turnOn.failure,
      emailMarketingCrmAutomation.turnOff.failure,
      emailMarketingCrmAutomation.saveTemplate.failure
    ],
    showApiError
  );
}
