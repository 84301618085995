import React from 'react';
import styled from 'styled-components';
import graphUp from 'assets/icons/graphUp.svg';
import payment from 'assets/icons/payment.svg';
import priceTag from 'assets/icons/priceTag.svg';
import { Hint } from 'shared-components/Hint';
import { formatString } from 'services/utils';
import { textRegularBold, textSubtitleBold } from 'layout/mixins';
import GrayableImage from 'assets/icons/GrayableImage';
import { localizationService } from 'services/localization';
import { CalculatorSuggestTranslations } from '../types';
import { deviceMedia } from '../../../../appConstants';

interface CalculatorSuggestProps {
  className?: string;
  data: any;
  isDataAvailable: boolean;
  translations: CalculatorSuggestTranslations;
}

export const CalculatorTitle = styled.h3`
  display: flex;
  align-items: center;
  ${textSubtitleBold};
  color: ${props => props.theme.configurableColors.color1};
  margin-top: 0;

  svg > g > g {
    fill: ${props => props.theme.configurableColors.color1} !important;
  }
`;

export const CalculatorSuggestBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 25px;

  @media ${deviceMedia.mobile} {
    flex-wrap: wrap;
    row-gap: 30px;
  }
`;

export const SuggestPoints = styled.div`
  width: 50%;
  padding-right: 10px;
  border-right: 1px solid ${props => props.theme.baseColors.grey};

  @media ${deviceMedia.mobile} {
    width: 100%;
    border-right: 0;
  }
`;

export const CalculatorSubTitle = styled.p`
  ${textRegularBold};
`;

const PointsList = styled.ul`
  padding: 0;
  list-style: none;
`;

const PointsItem = styled.li`
  position: relative;
  padding-left: 20px;
  margin-bottom: 5px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    width: 3px;
    height: 3px;
    background-color: #000;
    border-radius: 50%;
  }
`;

const SuggestData = styled.div`
  width: 50%;
  padding-left: 10px;

  @media ${deviceMedia.mobile} {
    width: 100%;
    padding-left: 0;
  }
`;

export const SuggestDataItem = styled.div<{ isActive: boolean }>`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  color: ${props => (props.isActive ? props.theme.baseColors.darkGrey : props.theme.baseColors.mediumGrey)};
`;

const StyledGrayableImage = styled(GrayableImage)`
  width: 50px;
  height: 50px;
`;

const SuggestDataItemLabel = styled.span`
  display: inline-block;
  max-width: 170px;
  width: 40%;
  padding: 0 10px;
  word-break: break-word;
`;

export const SuggestDataItemValue = styled.span<{ isActive: boolean }>`
  color: ${props => (props.isActive ? props.theme.configurableColors.color1 : props.theme.baseColors.mediumGrey)};
`;

export const CalculatorSuggestComponent = (props: CalculatorSuggestProps) => {
  const { className, isDataAvailable = false, translations } = props;

  const suggestionPoints: any = [
    translations.suggestionPoint1,
    translations.suggestionPoint2,
    translations.suggestionPoint3,
    translations.suggestionPoint4
  ];

  const suggestionProgram: any = {
    pointThreshold: {
      icon: graphUp,
      label: translations.pointThresholdLabel,
      formatData: (data: any) => formatString(`{0} ${translations.pointThresholdPoints}`, data)
    },
    reward: {
      icon: payment,
      label: translations.loyaltyReward,
      formatData: (data: any) =>
        localizationService.formatNumberWithCurrency({
          value: Number(data),
          isFixed: true
        })
    },
    cost: {
      icon: priceTag,
      label: translations.approximateCost,
      formatData: (data: any) =>
        localizationService.formatNumberWithCurrency({
          value: Number(data),
          isFixed: true
        })
    }
  };

  return (
    <div className={className} data-test-id="loyalty-calculator-suggest">
      <CalculatorTitle>
        {translations.calculatorSuggestTitle}{' '}
        <Hint text={translations.calculatorSuggestHint} placement="right" size={16} />
      </CalculatorTitle>
      <CalculatorSuggestBlock>
        <SuggestPoints data-test-id="loyalty-calculator-suggest-points-wrap">
          <CalculatorSubTitle>{translations.calculatorSuggestSubTitle}</CalculatorSubTitle>
          <PointsList data-test-id="loyalty-calculator-suggest-points-program">
            {suggestionPoints.map((point: any, index: number) => (
              <PointsItem key={index}>{point}</PointsItem>
            ))}
          </PointsList>
        </SuggestPoints>
        <SuggestData data-test-id="loyalty-calculator-suggest-points-icons-wrap">
          {Object.entries<any>(suggestionProgram).map(([name, data]) => (
            <SuggestDataItem
              key={name}
              isActive={isDataAvailable}
              data-test-id={`loyalty-calculator-suggest-points-icons-${name}`}
            >
              <StyledGrayableImage grayscale={!isDataAvailable} width="50px" height="50px" imgSrc={data.icon} />
              <SuggestDataItemLabel>{data.label}</SuggestDataItemLabel>
              <SuggestDataItemValue isActive={isDataAvailable}>
                <strong>{props.data[name] ? data.formatData(props.data[name]) : '--'}</strong>
              </SuggestDataItemValue>
            </SuggestDataItem>
          ))}
        </SuggestData>
      </CalculatorSuggestBlock>
    </div>
  );
};

export const CalculatorSuggest = styled(CalculatorSuggestComponent)`
  padding: 25px 0 0 0;
`;
