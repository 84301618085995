import { createAction, createAsyncAction } from 'typesafe-actions';
import { LoyaltyMember, LoyaltyMemberFormPayload } from './types';

const fetchLoyaltyMembers = createAsyncAction(
  '@loyalty/members/fetch',
  '@loyalty/members/success',
  '@loyalty/members/error'
)<LoyaltyMemberFormPayload, LoyaltyMember[], unknown>();

const resetLoyaltyMembersStore = createAction('@loyalty/members/resetStore', action => () => action());

export const loyaltyMembersActions = {
  fetchLoyaltyMembers,
  resetLoyaltyMembersStore
};
