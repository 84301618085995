import { createReducer } from 'typesafe-actions';
import { automationActions } from './actions';
import { AutomationState } from './types';

const defaultCampaignEditorState: AutomationState = {
  isCoreActive: true,
  templates: [],
  assignedTemplates: [],
  campaigns: {},
  loadingCoreStatus: false,
  loadingTemplates: false,
  loadingCampaignsStatus: false,
  fetchedCampaignsStatus: false,
  savingTemplate: false,
  showWelcomeModal: false,
  assignSuccessModal: { status: false },
  creatingCampaign: false
};

const {
  fetchAutomationTemplates,
  getAutomationCoreStatus,
  saveAutomationTemplate,
  setWelcomeModalState,
  setAssignSuccessModalState,
  getAutomationCampaignsStatus,
  createCampaign
} = automationActions;

export const AutomationReducer = createReducer(defaultCampaignEditorState)
  // Core
  .handleAction(getAutomationCoreStatus.request, state => ({
    ...state,
    loadingCoreStatus: true,
    fetchedCampaignsStatus: true
  }))
  .handleAction(getAutomationCoreStatus.success, (state, action: any) => ({
    ...state,
    isCoreActive: action.payload,
    loadingCoreStatus: false
  }))
  .handleAction(getAutomationCoreStatus.failure, state => ({ ...state, loadingCoreStatus: false }))
  // Templates
  .handleAction(fetchAutomationTemplates.request, state => ({ ...state, loadingTemplates: true }))
  .handleAction(fetchAutomationTemplates.success, (state, { payload }) => ({
    ...state,
    templates: payload.templates,
    assignedTemplates: payload.assignedTemplates,
    loadingTemplates: false
  }))
  .handleAction(fetchAutomationTemplates.failure, state => ({ ...state, loadingTemplates: false }))
  // Save
  .handleAction(saveAutomationTemplate.request, state => ({ ...state, savingTemplate: true }))
  .handleAction(saveAutomationTemplate.success, state => ({ ...state, savingTemplate: false }))
  .handleAction(saveAutomationTemplate.failure, state => ({ ...state, savingTemplate: false }))
  // Campaigns
  .handleAction(getAutomationCampaignsStatus.request, state => ({ ...state, loadingCampaignsStatus: true }))
  .handleAction(getAutomationCampaignsStatus.success, (state, action: any) => ({
    ...state,
    campaigns: action.payload,
    loadingCampaignsStatus: false
  }))
  .handleAction(getAutomationCampaignsStatus.failure, state => ({ ...state, loadingCampaignsStatus: false }))
  // Campaign Create
  .handleAction(createCampaign.request, state => ({ ...state, creatingCampaign: true }))
  .handleAction(createCampaign.success, state => ({ ...state, creatingCampaign: false }))
  .handleAction(createCampaign.failure, state => ({ ...state, creatingCampaign: false }))
  // Modals
  .handleAction(setWelcomeModalState, (state, action: any) => ({ ...state, ...action.payload }))
  .handleAction(setAssignSuccessModalState, (state, action: any) => ({ ...state, ...action.payload }));
